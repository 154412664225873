<template>
  <v-autocomplete
    label="Cobros Internos Pendientes"
    placeholder="Seleccione un cobro"
    :loading="loading"
    :items="items"
    item-text="code"
    item-value="id"
    return-object
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :search-input.sync="search"
    ref="auto"
    clearable
    :rules="isRequired ? onlyRequired : []"
  >
    <template v-slot:item="{ item }">
      {{ getText(item) }}
    </template>
  </v-autocomplete>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules.js";
import moment from 'moment';
export default {
  props: {
    reset: { type: Boolean, required: false },
    value: [Object],
    reload: [Boolean],
    isRequired: { type: Boolean, required: false, default: false },
    accountID: { type: String, required: true, default: undefined },
  },
  data: () => ({
    loading: true,
    items: [],
    search: "",
    onlyRequired: [rules.required],
  }),
  methods: {
    getText: (item) => {
      return (item.code + " - " + moment(String(item.created)).format("YYYY-MM-DD HH:mm")+" - T"+item.amount+" - S"+item.balance);
    },
    getInternalBilling(param = "") {
      this.loading = true;
      if (this.accountID != undefined && this.accountID != " " && this.accountID != "") {
        let uri =
          "/api/bussiness-connector/internal-billing/?internal_account_id=" +
          this.accountID +
          "&pending=true&search=";

        if (this.search) {
          uri += param;
        }
        requests.get(uri).then((res) => {
          if (res.status == 200) {
            this.items = res.data.results;
            this.loading = false;
          } else {
            console.log(res);
          }
        });
      } else {
        this.items = [];
        this.loading = false;
      }
    },
  },
  watch: {
    reset: function () {
      this.$refs.auto.reset();
    },
    reload: function () {
      this.getInternalBilling();
    },
    accountID: function () {
      this.getInternalBilling();
    },
    search: function () {
      this.getInternalBilling(this.search);
    },
  },
  mounted() {
    this.getInternalBilling();
  },
};
</script>
